import React from 'react'
import Header from '../components/header'
import Table from '../components/table'


const Home = () => {
  return (
    <>
      <Header />
      <Table />
    </>
  )
}

export default Home